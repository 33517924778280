/* the horizontal title cells */
.mbsc-timeline-header-date,
/* the vertical title cells */
.mbsc-timeline-resource-title,
/* the full vertical title cells */
.mbsc-timeline-resource,/* the empty corner of the schedule  */
.mbsc-timeline-resource-empty.mbsc-timeline-resource-col.mbsc-timeline-header-placeholder.mbsc-timeline-header-row-1.mbsc-windows.mbsc-ltr {
  background-color: #e0e7ff;
}

/* the line inside the schedule */
.mbsc-timeline-column.mbsc-flex-1-1.mbsc-windows.mbsc-ltr {
  border-color: #e8ebf0;
}

/* the line inside the schedule vertical title cells*/
.mbsc-timeline-resource.mbsc-windows.mbsc-ltr,
/* the line inside the schedule horizontal title cells*/
.mbsc-timeline-header-date.mbsc-windows.mbsc-ltr,/* the empty corner of the schedule  */
.mbsc-timeline-resource-empty.mbsc-timeline-resource-col.mbsc-timeline-header-placeholder.mbsc-timeline-header-row-1.mbsc-windows.mbsc-ltr {
  border-color: #cdcdd3;
}

/* the vertical title cells */
.css-vp3bjg-SchedulerResource,
.css-1lm8gvq-SchedulerResource {
  justify-content: center;
}

.css-1rg3gd9-SchedulerEvent,
.css-1p438h1-SchedulerEvent {
  border-radius: 20px;
  border: none;
}

.mbsc-timeline-header {
  z-index: unset !important;
}

.mbsc-timeline {
  z-index: 0 !important;
}

.mbsc-timeline-resource-col {
  width: 7em !important;
}