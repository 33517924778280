.shepherd-has-cancel-icon.shepherd-element.shepherd-centered.shepherd-enabled,
.shepherd-has-title {
  border: 3px solid black;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: black !important;
  padding: 1em;
}

.shepherd-title {
  color: white !important;
}

.shepherd-button {
  background: rgb(59 130 246) !important;
  border: 3px solid black !important;
}

.shepherd-button-secondary {
  background: white !important;
}
