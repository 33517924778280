/* // https://www.swyx.io/writing/tailwind-unreset 
we rely on postcss-nested to nest css
more info: https://tailwindcss.com/docs/using-with-preprocessors/#nesting
*/

.unreset a {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
  text-decoration-line: underline;
}
.unreset p {
  margin-top: 1rem/* 16px */;
  margin-bottom: 1rem/* 16px */;
}

.unreset blockquote,
.unreset figure {
  margin-top: 1rem/* 16px */;
  margin-bottom: 1rem/* 16px */;
  margin-left: 2.5rem/* 40px */;
  margin-right: 2.5rem/* 40px */;
}

.unreset hr {
  border-width: 1px;
}

.unreset h1 {
  font-size: 2.25rem/* 36px */;
  line-height: 2.5rem/* 40px */;
  font-weight: 700;
  margin-top: 0.5rem/* 8px */;
  margin-bottom: 0.5rem/* 8px */;
}

.unreset h2 {
  font-size: 1.5rem/* 24px */;
  line-height: 2rem/* 32px */;
  font-weight: 700;
  margin-top: 0.75rem/* 12px */;
  margin-bottom: 0.75rem/* 12px */;
}

.unreset h3 {
  font-size: 1.125rem/* 18px */;
  line-height: 1.75rem/* 28px */;
  font-weight: 700;
  margin-top: 1rem/* 16px */;
  margin-bottom: 1rem/* 16px */;
}

.unreset h4 {
  font-size: 1rem/* 16px */;
  line-height: 1.5rem/* 24px */;
  font-weight: 700;
  margin-top: 1.25rem/* 20px */;
  margin-bottom: 1.25rem/* 20px */;
}

.unreset h5 {
  font-size: 0.875rem/* 14px */;
  line-height: 1.25rem/* 20px */;
  font-weight: 700;
  margin-top: 1.5rem/* 24px */;
  margin-bottom: 1.5rem/* 24px */;
}

.unreset h6 {
  font-size: 0.75rem/* 12px */;
  line-height: 1rem/* 16px */;
  font-weight: 700;
  margin-top: 2.5rem/* 40px */;
  margin-bottom: 2.5rem/* 40px */;
}

.unreset ul,
.unreset menu {
  list-style-type: disc;
  margin-top: 1rem/* 16px */;
  margin-bottom: 1rem/* 16px */;
  padding-left: 2.5rem/* 40px */;
}

.unreset li > p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.unreset ol {
  list-style-type: decimal;
  margin-top: 1rem/* 16px */;
  margin-bottom: 1rem/* 16px */;
  padding-left: 2.5rem/* 40px */;
}

b,
strong {
  font-weight: bold;
}

pre {
  margin-top: 1rem/* 16px */;
  margin-bottom: 1rem/* 16px */;
}

